.tagify {
  --tags-border-color: #e5e5e500 !important;
  --tag-border-radius: 181px;
  --tag-inset-shadow-size: 1.6em;

  border: 0 solid #c7deff !important;
  padding: 0;
  padding-right: 7.5rem !important;
  width: 100% !important;
  border-bottom: 0.5px solid var(--linecolor) !important;
  border-radius: 0;
}
