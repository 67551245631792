.tagify {
  --tags-border-color: #e5e5e500;
  --tag-border-radius: 181px;
  --tag-inset-shadow-size: 1.6em;

  background-color: white;
  border-radius: 18px;
  padding: 15px 15px 15px 60px;
  max-width: 100%;
  overflow-x: scroll;
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
}

.radius {
  border-radius: 18px !important;
}
